import { OfferTypeEnum } from "domain/offerType";
import { ContractType } from "domain/contract";
import { OrderType } from "domain/orderType";

export function truncateText(text: string, maxLength: number) {
    if (text == null) return null;

    if (text.length < maxLength) return text;

    return text.slice(0, maxLength) + "...";
}

export function getPriceLabelForOffer(type: OfferTypeEnum, orderType: OrderType) {
    if (orderType == OrderType.Tas) {
        return "TAS Price";
    }
    else if ([OfferTypeEnum.Cash, OfferTypeEnum.FixBasisOnHta, OfferTypeEnum.FixFuturesOnBasis].includes(type))
        return "Cash Price";
    else if (type === OfferTypeEnum.Hta)
        return "Futures Price";
    else
        return "Price";
}

export function getPriceLabelForContract(type: ContractType) {
    if (type === ContractType.Cash)
        return "Cash Price";
    else if (type === ContractType.Hta)
        return "Futures Price";
    else
        return "Price";
}

export function formatOrderDisplayId(value: string): string {
    if(value != null && value.length > 10)
         return value.slice(-10);
 
     return value; 
 }
 
 
export function formatCustomerWillSell(value: boolean): string {
    return value ? "Sell" : "Buy";
 }

 //Yes, there are packages out there that do this, but they're bloated with irregular rules
 const irregularPluralizations: {[index: string]: string} = {
    //example
    //goose: "geese"
 }
 export function pluralize(count: number, text: string, excludeNumber?: boolean) {

    if (count === 1) return excludeNumber
        ? text
        : `1 ${text}`;
    
    text = irregularPluralizations[text] != null
        ? text = irregularPluralizations[text]
        : text + 's';

    if (excludeNumber) return text;

    return `${count} ${text}`;
 }